<template>
  <div>
    <jzsy-header :show-home="false" title="人员管理"></jzsy-header>
    <div class="wrapper">
      <ValidationObserver ref="form">
        <ValidationProvider rules="required" name="姓名" v-slot="{ errors }">
          <md-input-item
            class="input-item"
            type="text"
            placeholder="人员姓名"
            v-model="form.name"
            is-title-latent
            :maxlength="8"
            is-highlight
            title="姓名"
            :error="errors[0]"
            :disabled="loading"
          >
            <md-icon name="user" slot="left" color="#9579E5"></md-icon>
          </md-input-item>
        </ValidationProvider>
        <ValidationProvider
          rules="required|phone"
          name="手机号"
          v-slot="{ errors }"
        >
          <md-input-item
            class="input-item"
            type="phone"
            placeholder="人员手机号"
            v-model="form.phone"
            is-title-latent
            :maxlength="11"
            is-highlight
            title="手机号"
            :error="errors[0]"
            :disabled="loading"
          >
            <md-icon name="mobile-phone" slot="left" color="#9579E5"></md-icon>
          </md-input-item>
        </ValidationProvider>
        <div class="password-tips">默认密码：123456</div>
        <md-button
          class="submit-btn"
          type="primary"
          size="small"
          :plain="true"
          :loading="loading"
          @click="addHandle"
          >添加</md-button
        >
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import AdminApi from "@/api/admin";

export default {
  data() {
    return {
      form: {
        name: "",
        phone: "",
        fromAdmin: true,
      },
      loading: false,
    };
  },
  computed: {
    referralCode: {
      get: function () {
        return this.form.referralCode ? this.form.referralCode : this.form.name;
      },
      set: function (value) {
        this.form.referralCode = !value ? "" : value.replace(/\s+/g, "");
      },
    },
  },
  methods: {
    addHandle() {
      this.$refs.form.validate().then((res) => {
        if (res) {
          this.loading = true;
          AdminApi.addStaff(this.form)
            .then(() => {
              this.$router.push("/staff/list-normal");
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
  },
};
</script>

<style scoped>
.right-nav-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 92px;
  margin-right: 30px;
}
.wrapper {
  padding: 50px;
  font-size: 24px;
}
.password-tips {
  margin-top: 50px;
  color: #5a5a5a;
}
.submit-btn {
  margin-top: 50px;
}
</style>