import request from '../util/request'
import qs from 'qs'

export default {
	addStaff(data) {
		return request({
			url: '/admin/addStaff',
			method: 'POST',
			data: qs.stringify(data)
		})
	},
	listStaff(pageNo) {
		return request({
			url: '/admin/staffList/' + pageNo,
			method: 'GET'
		})
	},
	listQuitedStaff(lastId) {
		return request({
			url: '/admin/quitedStaffList/' + lastId,
			method: 'GET'
		})
	},
	resetPassword(staffId) {
		return request({
			url: '/admin/resetPassword',
			method: 'POST',
			data: qs.stringify({
				staffId: staffId
			})
		})
	},
	staffQuit(staffId) {
		return request({
			url: '/admin/staffQuit',
			method: 'POST',
			data: qs.stringify({
				staffId: staffId
			})
		})
	},
	getStaff(staffId) {
		return request({
			url: '/admin/staff/' + staffId,
			method: 'GET'
		})
	},
	updateStaff(staff) {
		return request({
			url: '/admin/updateStaff',
			method: 'POST',
			data: staff
		})
	},
	reinstateStaff(staffId) {
		return request({
			url: '/admin/reinstateStaff',
			method: 'POST',
			data: qs.stringify({
				staffId: staffId
			})
		})
	}
}